import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Suspense as _Suspense, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tray-theme-editor__commerce" }
const _hoisted_2 = { class: "tray-theme-editor__section tray-theme-editor__section--spaced" }
const _hoisted_3 = { class: "tray-theme-editor__label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LayoutEditor = _resolveComponent("LayoutEditor")!
  const _component_Pages = _resolveComponent("Pages")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Display = _resolveComponent("Display")!
  const _component_Timeline = _resolveComponent("Timeline")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("section", _hoisted_1, [
      _createVNode(_component_LayoutEditor, {
        onOpen: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('open')))
      }),
      (_openBlock(), _createBlock(_Suspense, null, {
        default: _withCtx(() => [
          _createVNode(_component_Pages)
        ]),
        fallback: _withCtx(() => [
          _createElementVNode("section", _hoisted_2, [
            _createVNode(_component_Icon, {
              class: "tray-theme-editor__icon",
              name: "home",
              prefix: "fa"
            }),
            _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t('pages.home')), 1),
            _createVNode(_component_Icon, {
              class: "tray-theme-editor__icon tray-theme-editor__icon--last",
              name: "chevron-down",
              prefix: "fa"
            })
          ])
        ]),
        _: 1
      }))
    ]),
    _createVNode(_component_Display),
    _createVNode(_component_Timeline)
  ], 64))
}