import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  id: "display-change",
  class: "tray-theme-editor__display-list__dropdown"
}
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("section", {
    class: "tray-theme-editor__display-list",
    onMouseover: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.activeDropdown && _ctx.activeDropdown(...args))),
    onMouseleave: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.disableDropdown && _ctx.disableDropdown(...args)))
  }, [
    _createVNode(_component_Icon, {
      class: "tray-theme-editor__icon",
      name: _ctx.activeResolution.icon,
      prefix: "fa"
    }, null, 8, ["name"]),
    _createVNode(_component_Icon, {
      class: "tray-theme-editor__icon tray-theme-editor__icon--last",
      name: "chevron-down",
      prefix: "fa"
    }),
    _withDirectives(_createElementVNode("nav", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.resolutions, (resolution) => {
        return (_openBlock(), _createElementBlock("section", {
          key: resolution,
          class: "tray-theme-editor__display-list__dropdown-item",
          onClick: ($event: any) => (_ctx.changeResolution(resolution))
        }, [
          _createVNode(_component_Icon, {
            class: "tray-theme-editor__icon",
            name: resolution.icon,
            prefix: "fa"
          }, null, 8, ["name"]),
          _createTextVNode(" " + _toDisplayString(resolution.name), 1)
        ], 8, _hoisted_2))
      }), 128))
    ], 512), [
      [_vShow, _ctx.dropdown]
    ])
  ], 32))
}