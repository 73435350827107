
import { defineComponent } from 'vue';
import Icon from '@/components/Icon.vue';
import { mapActions, mapGetters } from 'vuex';
import { CustomPageInterface } from '@/models/custom-page/CustomPage.interface';

export default defineComponent({
  name: 'Pages',

  components: {
    Icon,
  },
  data() {
    return {
      dropdown: false,
    };
  },
  computed: {
    ...mapGetters('themeEditor', {
      pageList: 'pageList',
      pageActive: 'pageActive',
    }),
  },
  methods: {
    ...mapActions('themeEditor', {
      apiPageChangePagePreview: 'apiPageChangePagePreview',
    }),
    changeActivePage(page: CustomPageInterface): void {
      this.apiPageChangePagePreview(page);
      this.dropdown = false;
    },
  },
});
