import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tray-theme-editor__timeline" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Tooltip = _resolveComponent("Tooltip")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _withDirectives(_createElementVNode("section", {
      class: "tray-theme-editor__section tray-theme-editor__timeline-steps",
      "test-id": "undo",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.undo && _ctx.undo(...args)))
    }, [
      _createVNode(_component_Tooltip, {
        info: _ctx.$t('navigation.undo'),
        icon: false,
        placement: "bottom"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Icon, {
            class: "tray-theme-editor__timeline-icon",
            name: "level-up-alt",
            prefix: "fa",
            rotate: -90
          })
        ]),
        _: 1
      }, 8, ["info"])
    ], 512), [
      [_vShow, false]
    ]),
    _withDirectives(_createElementVNode("section", {
      "test-id": "redo",
      class: "tray-theme-editor__section tray-theme-editor__timeline-steps",
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.redo && _ctx.redo(...args)))
    }, [
      _createVNode(_component_Tooltip, {
        info: _ctx.$t('navigation.redo'),
        icon: false,
        placement: "bottom"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Icon, {
            class: "tray-theme-editor__timeline-icon",
            name: "level-down-alt",
            prefix: "fa",
            rotate: -90
          })
        ]),
        _: 1
      }, 8, ["info"])
    ], 512), [
      [_vShow, false]
    ]),
    _createElementVNode("section", {
      class: "tray-theme-editor__section tray-theme-editor__timeline-preview",
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$router.push({ name: 'Pré visualização' })))
    }, [
      _createVNode(_component_Icon, {
        class: "tray-theme-editor__icon",
        name: "play",
        prefix: "fa"
      }),
      _createTextVNode(" " + _toDisplayString(_ctx.$t('timeline.preview')), 1)
    ])
  ]))
}