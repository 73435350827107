import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["title"]
const _hoisted_2 = { class: "tray-theme-editor__section__pages-label" }
const _hoisted_3 = { class: "tray-theme-editor__section__dropdown tray-theme-editor__section__pages-nav" }
const _hoisted_4 = ["test-page", "title", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("section", {
    class: "tray-theme-editor__section tray-theme-editor__section--spaced tray-theme-editor__section__pages",
    onMouseover: _cache[0] || (_cache[0] = ($event: any) => (_ctx.dropdown = true)),
    onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.dropdown = false))
  }, [
    _createElementVNode("div", {
      class: "tray-theme-editor__section",
      title: _ctx.pageActive.name
    }, [
      _createVNode(_component_Icon, {
        class: "tray-theme-editor__icon",
        name: "file",
        prefix: "far"
      }),
      _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.pageActive.name), 1)
    ], 8, _hoisted_1),
    _createVNode(_component_Icon, {
      class: "tray-theme-editor__icon tray-theme-editor__icon--last",
      name: "chevron-down",
      prefix: "fa"
    }),
    _withDirectives(_createElementVNode("nav", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pageList, (current) => {
        return (_openBlock(), _createElementBlock("section", {
          key: current.id,
          "test-page": current.name,
          title: current.name,
          class: "tray-theme-editor__section__dropdown-item tray-theme-editor__section__pages-link",
          onClick: ($event: any) => (_ctx.changeActivePage(current))
        }, _toDisplayString(current.title || current.name), 9, _hoisted_4))
      }), 128))
    ], 512), [
      [_vShow, _ctx.dropdown]
    ])
  ], 32))
}