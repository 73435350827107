
import { defineComponent } from 'vue';
import Pages from '@/components/Pages.vue';
import LayoutEditor from '@/components/LayoutEditor.vue';
import Display from '@/components/display/Display.vue';
import Timeline from '@/components/Timeline.vue';
import Icon from '@/components/Icon.vue';

export default defineComponent({
  components: {
    Pages,
    LayoutEditor,
    Display,
    Timeline,
    Icon,
  },
  emits: ['open'],
});
